import * as React from "react"
import styled from 'styled-components/macro'
import Title from "../../common/Title";
import Subtitle from "../../common/Subtitle";
import ExchangeImage from '../../images/dolomite-devices-header-image.png'
import {Link} from "gatsby";

const GetStartedSectionWrapper = styled.div`
  width: 100%;
  color: #f9f9f9;
  padding-bottom: 100px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  overflow: hidden;
`

const Content = styled.div`
  width: 100%;
  max-width: 1050px;
  margin: 10px auto 0;
  display: inline-block;
  text-align: left;
  
  @media screen and (max-width: 1100px) {
    padding: 0 50px;
  }
  
  @media screen and (max-width: 880px) {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
  }
`

const ContentWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 60%;
  margin-top: 15%;
  margin-right: -20%;
  
  @media screen and (max-width: 880px) {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin-top: 0;
    text-align: center;
  }
`

const ContentTitle = styled(Title)`
  font-size: 48px;
  line-height: 54px;
  
  @media screen and (max-width: 1100px) {
    font-size: 42px;
    line-height: 48px;
  }
  
  @media screen and (max-width: 880px) {
    font-size: 2em;
  }
`

const ContentSubtitle = styled(Subtitle)`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  color: #d5d6e1;
  
  @media screen and (max-width: 1100px) {
    font-size: 16px;
  }
  
  @media screen and (max-width: 880px) {
    font-size: 1em;
    line-height: 20px;
    width: 80%;
    margin: 0 auto 8px;
  }
`

const ImageWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 60%;
  pointer-events: none;
  
  img {
    width: 150%;
    height: auto;
    margin-left: -25%;
    margin-top: 20px;
  }
  
  @media screen and (max-width: 1100px) {
    img {
      margin-left: -45%;
    }
  }
  
  @media screen and (max-width: 880px) {
    width: 100%;
    
    img {
      width: 100%;
      margin: 0;
    }
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;

  a {
    text-decoration: none;
    color: inherit;
  }
`

const Button = styled.div`
  border-radius: 5px;
  color: white;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  width: fit-content;
  padding: 12px 24px;
  display: inline-block;
  vertical-align: top;
  
  @media screen and (max-width: 880px) {
    display: inline-block;
    min-width: 50%;
    font-size: 16px;
    padding: 10px 20px;
    margin: 10px auto;
  }
`

const AppButton = styled(Button)`
  background: #8fc942;/*#68b04d*/
  margin-right: 20px;
  
  :hover {
    background: #96d148;/*#8fc942*/
  }
  
  @media screen and (max-width: 880px) {
    margin-right: auto;
  }
`

const QuickStartButton = styled(Button)`
  background: #565A69;
  
  :hover {
    background: #6C7284;
  }
`

const GuideLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
`

// markup
const GetStartedSection = () => {

  return (
    <GetStartedSectionWrapper>
      <Content>
        <ContentWrapper>
          <ContentTitle>
            Ready to get started?
          </ContentTitle>
          <ContentSubtitle>
            Dive right in, or check out our quick start guide to help you begin:
          </ContentSubtitle>
          <ButtonsWrapper>
            <GuideLink href={'https://app.dolomite.io'}>
              <AppButton>
                Launch App
              </AppButton>
            </GuideLink>
            <GuideLink to={'/QuickStart/Desktop'}>
              <QuickStartButton>
                Quickstart Guide
              </QuickStartButton>
            </GuideLink>
          </ButtonsWrapper>
        </ContentWrapper>
        <ImageWrapper>
          <img src={ExchangeImage} alt={'Dolomite Exchange image'} />
        </ImageWrapper>
      </Content>
    </GetStartedSectionWrapper>
  )
}

export default GetStartedSection
